import { Link } from "react-router-dom";
import { renderCustomColumnDefs } from "../../../../../shared/Table/table.utils";
import { formatDate, renderAddress, renderAmount } from "../../../../../utils";
import { payoutTypeOptions } from "../../../../../constants/select";

// Config for table and forms
const tableConfig = [
  {
    Header: "#",
    accessor: "",
    Cell: (row) => {
      return row.row.id * 1 + 1;
    },
    disableSortBy: true,
    disableFilters: true,
    width: 10,
  },
  {
    header: "Job Load #",
    id: "jobId",
    accessorFn: (row) => row.jobId?._id,
    minWidth: 100,
    cell: ({ row: { original }, getValue }) => {
      const value = getValue();
      return (
        <Link
          className="text-primary-600 hover:underline"
          to={`/jobs/${original.jobId?._id}`}
        >
          {original.jobId?.loadNumber}
        </Link>
      );
    },
  },
  {
    id: "appointmentDate",
    header: "Date",
    width: 70,
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value) {
        return null;
      }
      return formatDate(value?.appointmentDate, null, true);
    },
  },
  {
    id: "orderNumber",
    visible: true,
    cell: ({ row: { original }, getValue }) => {
      return getValue();
    },
  },
  {
    Header: "Pickup Location",
    accessorFn: (row) => row.route?.origin,
    cell: ({ getValue }) => renderAddress(getValue()),
    width: 90,
  },
  {
    Header: "Dropoff Location",
    accessorFn: (row) => row.route?.destination,
    cell: ({ getValue }) => renderAddress(getValue()),
    width: 90,
  },
  {
    Header: "Delivery Type",
    id: "loadType",
    accessorKey: "loadType",
    width: 90,
    cell: ({ getValue }) => getValue(),
  },
  {
    header: "Standby Hours",
    id: "waitTime",
    visible: true,
    required: true,
    cell: ({ getValue }) => getValue()?.total,
  },
  // { id: "driver", minWidth: 100, cell: ({ getValue }) => getValue()?.fullName },
  {
    id: "route",
    header: "Total Miles",
    width: 90,
    cell: ({ getValue, row }) => {
      const value = getValue();
      if (row.original.useCustomManifestMileage) {
        return value && <div>{row.original.customManifestMileage} mi</div>;
      }
      return value && <div>{value.defaultMileage || value.mileage} mi</div>;
    },
  },
  {
    Header: "commissionType",
    id: "payoutType",
    width: 90,
    editable: {
      type: "select",
      options: payoutTypeOptions,
    },
    cell: ({ getValue }) => getValue(),
  },
  {
    header: "Payout Rate",
    id: "payoutRate",
    size: 400,
    editable: {
      type: "number",
    },
    cell: ({ getValue }) => renderAmount(getValue()),
  },
  {
    header: "Supplemental",
    id: "payoutSupplemental",
    width: 90,
    editable: {
      type: "number",
    },
    cell: ({ getValue }) => renderAmount(getValue()),
  },
  {
    header: "Total",
    width: 90,
    id: "payoutTotal",
    cell: ({ getValue }) => renderAmount(getValue()),
  },
];

export const COLUMN_DEFINITIONS = renderCustomColumnDefs(tableConfig);
